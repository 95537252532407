import { formatDistanceStrict, isValid } from 'date-fns';
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';

import { getOffsetLabel } from './getOffsetLabel';

export type FormatDateParam = {
  date: string;
  dateFormat?: string;
  displayLocalTime?: boolean;
  showOffset?: boolean;
};

export const formatDate = (param: FormatDateParam) => {
  const { date, dateFormat = 'yyyy-MM-dd HH:mm:ss', displayLocalTime, showOffset } = param;

  const parsedDate = zonedTimeToUtc(date, 'UTC');

  if (!isValid(parsedDate)) {
    return { dateLabel: 'Invalid date', fromNow: '' };
  }

  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timeZone = displayLocalTime ? localTimeZone : 'UTC';
  const formattedDate = formatInTimeZone(parsedDate, timeZone, dateFormat);

  const fromNow = formatDistanceStrict(parsedDate, new Date(), { addSuffix: true });
  const dateLabel = showOffset
    ? `${formattedDate} (${displayLocalTime ? getOffsetLabel(formattedDate) : 'UTC'})`
    : formattedDate;

  return { dateLabel, fromNow };
};
