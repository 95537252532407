import { useState, useCallback, useMemo, SyntheticEvent, useEffect } from 'react';

import { BaseRow } from '@openx/types';

import { ShowMoreButton } from './ShowMoreButton';
import { TableRow, TableRowProps } from './TableRow';

export interface TableRowTreeProps<RowT extends BaseRow> extends TableRowProps<RowT> {
  rowData: RowT;
  isForcedExpanded?: (rowData: RowT) => boolean;
  depth?: number;
  treeLevelItems?: number;
  fetchTreeChildren?: (data: RowT) => Promise<RowT[]>;
  updateChildren?: (data: RowT) => void;
  dataTest?: string;
  noItemsLabel?: (data: RowT) => string;
}

const ROW_DATA_CHILDREN_KEY = 'children';

export function TableRowTree<RowT extends BaseRow>(props: Readonly<TableRowTreeProps<RowT>>): JSX.Element {
  const {
    rowData,
    columns,
    iconCellRenderer,
    isForcedExpanded,
    onRowClick,
    depth = 0,
    treeLevelItems = 20,
    fetchTreeChildren,
    updateChildren,
    dataTest = 'table-row-expand',
    noItemsLabel,
  } = props;
  const [showItems, setShowItems] = useState(treeLevelItems);

  const [children, setChildren] = useState<RowT[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState<boolean>(isForcedExpanded ? isForcedExpanded(rowData) : false);

  useEffect(() => {
    if (!rowData[ROW_DATA_CHILDREN_KEY]) return;
    setChildren(rowData[ROW_DATA_CHILDREN_KEY]);
  }, [rowData]);

  useEffect(() => {
    if (isForcedExpanded?.(rowData) && children === null) {
      setLoading(true);
      fetchTreeChildren?.(rowData)
        .then(data => {
          setChildren(data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [fetchTreeChildren, isForcedExpanded, rowData, children]);

  const updateRowChildren = useCallback(
    (updatedChild: RowT) => {
      const sortedArray = [...(children?.filter(child => child.id !== updatedChild.id) ?? []), updatedChild].sort(
        (a, b) => {
          if (a.name && b.name) {
            return a.name.localeCompare(b.name);
          }
          return -1;
        }
      );

      setChildren(sortedArray);
    },
    [children]
  );

  const onClick = useCallback(
    (data: RowT, e: SyntheticEvent) => {
      // Check attribute expand row only when expand IconButton is clicked
      const attr = (e.target as HTMLElement).dataset.expandicon;
      const { parentNode } = e.target as HTMLElement;
      const parentAttr = parentNode && (parentNode as HTMLElement).dataset.expandicon;

      if ([attr, parentAttr].indexOf('expandRow') !== -1) {
        if (fetchTreeChildren && children === null) {
          setLoading(true);
          fetchTreeChildren(rowData).then(data => {
            setLoading(false);
            setChildren(data);
          });
        }
        setExpanded(expanded => !expanded);
      } else {
        onRowClick?.(data, e);
      }
    },
    [children, fetchTreeChildren, onRowClick, rowData]
  );

  const childrenToRender = useMemo(() => {
    const childrenToRender = children
      ?.slice(0, showItems)
      .map((child, id) => (
        <TableRowTree
          {...props}
          key={child.id ?? id}
          rowData={child}
          depth={depth + 1}
          dataTest={`table-row-expand-${depth + 1}`}
          updateChildren={updateRowChildren}
        />
      ));
    const colspan = iconCellRenderer ? columns.length + 1 : columns.length;
    if (childrenToRender && children && !children.length) {
      childrenToRender.push(
        <ShowMoreButton
          key="no-items"
          colspan={colspan}
          label={noItemsLabel?.(rowData) ?? 'No accounts found'}
          depth={depth + 1}
        />
      );
    }
    if (childrenToRender && children && children.length > showItems) {
      childrenToRender.push(
        <ShowMoreButton
          key="show-more"
          colspan={colspan}
          depth={depth + 1}
          onClick={() => setShowItems(showItems + treeLevelItems)}
        />
      );
    }
    return childrenToRender;
  }, [
    children,
    columns,
    depth,
    props,
    rowData,
    showItems,
    treeLevelItems,
    iconCellRenderer,
    noItemsLabel,
    updateRowChildren,
  ]);

  const additionalRenderParams = {
    depth,
    expanded,
    isFetching: loading,
  };

  const backgroundColor = depth ? { backgroundColor: `rgba(0, 0, 0, ${depth * 0.02});` } : undefined;

  return (
    <>
      <TableRow
        {...props}
        sx={[{ cursor: 'default' }, backgroundColor, ...(Array.isArray(props.sx) ? props.sx : [props.sx])]}
        onRowClick={onClick}
        data-test={dataTest}
        rowData={{ ...rowData, ...additionalRenderParams, updateChildren }}
      />
      {expanded && childrenToRender}
    </>
  );
}
