import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Box, Divider, Grid, type GridSize, IconButton, Tooltip, type TooltipProps, Typography } from '@mui/material';
import type { ReactNode } from 'react';

type FormSectionProps = {
  children?: ReactNode;
  customGrid?: boolean;
  ['data-test']?: string;
  description?: ReactNode;
  divider?: boolean;
  historyMode?: boolean;
  info?: ReactNode;
  leftColumnSize?: GridSize;
  rightColumnSize?: GridSize;
  title: string;
  tooltipPlacement?: TooltipProps['placement'];
};

export function FormSection(props: FormSectionProps): JSX.Element {
  const {
    children,
    customGrid,
    description,
    divider,
    historyMode = false,
    info,
    leftColumnSize = 3,
    rightColumnSize = 9,
    title,
    tooltipPlacement = 'top',
  } = props;
  const dataTest = props['data-test'] || title.toLowerCase().replace(/\s/g, '-');

  if (historyMode) {
    return (
      <>
        <Typography data-test="group-title" mb={1.5} pt={1} sx={{ '&:first-of-type': { pt: 0 } }} variant="h3">
          {title}
        </Typography>
        {children}
      </>
    );
  }

  return (
    <Box data-test={dataTest} width="100%">
      <Grid container p={4}>
        <Grid item xs={leftColumnSize}>
          <Box pr={3}>
            <Typography
              data-test="title"
              mb={1}
              sx={{
                ':before': {
                  borderTopColor: 'primary.main',
                  borderTopStyle: 'solid',
                  borderTopWidth: '1px ',
                  content: '""',
                  display: 'block',
                  marginBottom: '8px',
                  width: '12px',
                },
              }}
              variant="h2"
            >
              <Box component={'span'} display={'flex'}>
                {title}
                {info && (
                  <Tooltip placement={tooltipPlacement} sx={{ ml: '10px' }} title={info}>
                    <IconButton size="small">
                      <InfoRoundedIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </Typography>

            {description && (
              <Typography
                color="textSecondary"
                component="div"
                data-test="description"
                sx={{ wordBreak: 'break-word' }}
                variant="body1"
              >
                {description}
              </Typography>
            )}
          </Box>
        </Grid>
        {!customGrid ? (
          <Grid item xs={rightColumnSize}>
            {children}
          </Grid>
        ) : (
          children
        )}
      </Grid>

      {divider && <Divider />}
    </Box>
  );
}
