import { TableRow, TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Button } from '@openx/components/core/Button';

export interface ShowMoreButtonProps {
  onClick?: () => void;
  depth?: number;
  disabled?: boolean;
  colspan?: number;
  label?: string;
}

const StyledButton = styled(Button)`
  font-size: 12px;
`;

const StyledTableCell = styled(TableCell)`
  padding: ${({ theme }) => theme.spacing(1.2)};
`;

const StyledTableRow = styled(TableRow)<{ depth: number }>`
  background-color: rgba(0, 0, 0, ${({ depth }) => depth * 0.02});
`;

export function ShowMoreButton({
  depth = 0,
  disabled = false,
  colspan = 5,
  label = 'Show more',
  onClick = () => {},
}: ShowMoreButtonProps): JSX.Element {
  return (
    <StyledTableRow depth={depth} data-test="show-more-button">
      <StyledTableCell colSpan={colspan} align="center">
        <StyledButton onClick={onClick} variant="text" disabled={disabled}>
          {label}
        </StyledButton>
      </StyledTableCell>
    </StyledTableRow>
  );
}
