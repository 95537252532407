export const Criteria = {
  ALL: 'AND',
  ANY: 'OR',
} as const;

export type CriteriaValue = (typeof Criteria)[keyof typeof Criteria];

export enum ComparisonType {
  EQUALS = '==',
  DOES_NOT_EQUAL = '!=',
  ENDS_WITH = '=$',
  CONTAINS = '=?',
  BEGINS_WITH = '=^',
  DOES_NOT_END_WITH = 'NOT =$',
  DOES_NOT_CONTAIN = 'NOT =?',
  DOES_NOT_BEGIN_WITH = 'NOT =^',
  LESS_THAN = '<',
  LESS_THAN_OR_EQUAL_TO = '<=',
  GREATER_THAN = '>',
  GREATER_THAN_OR_EQUAL_TO = '>=',
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
  INTERSECTS = 'INTERSECTS',
  INVERSE_IN = 'INVERSE IN',
  EQ = 'EQ',
  NOT_INTERSECTS = 'NOT INTERSECTS',
}

export enum AuditTrailComparisonType {
  EQUALS = '==',
  DOES_NOT_EQUAL = '!=',
  ENDS_WITH = '=$',
  CONTAINS = '=?',
  BEGINS_WITH = '=^',
  DOES_NOT_END_WITH = 'NOT =$',
  DOES_NOT_CONTAIN = 'NOT =?',
  DOES_NOT_BEGIN_WITH = 'NOT =^',
  LESS_THAN = '<',
  LESS_THAN_OR_EQUAL_TO = '<=',
  GREATER_THAN = '>',
  'GREATER THAN OR EQUAL TO' = '>=',
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
  'CONTAINS ANY OF' = 'INTERSECTS',
  INVERSE_IN = 'INVERSE IN',
  EQ = 'EQ',
  'CONTAINS NONE OF' = 'NOT INTERSECTS',
}

export const AuditTrailSectionMapping: Record<string, string> = {
  ads: 'Formats and Ad Sizes',
  audience: 'Audience',
  categories: 'Categories',
  content: 'Inventory and Content',
  domain: 'Category',
  geographic: 'Geographic',
  technographic: 'Technology and Devices',
  url_targeting: 'Domains',
  video: 'Video',
  viewability: 'Viewability',
};

export const AuditTrailLabels: Record<string, string> = {
  account: 'Publisher ID',
  adunit: 'Adunit',
  adunit_max_duration: 'Maximum Ad Duration',
  adunit_size: 'Ad Unit Size',
  api_framework: 'API Frameworks',
  app_bundle_id: 'App Bundle ID',
  audience_type: 'Intended Audience',
  browser: 'Browser',
  categories: 'Categories',
  circles: 'Location Radius',
  city: 'City',
  connection_speed: 'Connection Speed',
  connection_type: 'Connection Type',
  content_inter_dimension_operator: 'Inventory and Content Inter Dimension Operator',
  continent: 'Continent',
  country: 'Country',
  custom: 'Custom Variables',
  device: 'Device',
  device_id: 'Device ID',
  device_type: 'Device Type',
  dma: 'DMA',
  dmp_segments: '3rd Party Data',
  enabled: 'Enabled',
  exclude_non_direct: 'Exclude Indirect Inventory',
  excludes: 'Excludes',
  format: 'Format',
  https: 'HTTPS',
  include_open_bidding_inventory: 'Include Google Open Bidding Inventory',
  includes: 'Includes',
  instance: 'Instance Hash',
  inter_dimension_operator: 'Inter Dimension Operator',
  inter_dimension_operator_display: 'Matching',
  isp_carrier: 'Mobile Carrier',
  keywords: 'Keywords',
  language: 'Language',
  latitude: 'Latitude Range',
  liveintent: 'LiveIntent',
  location_source: 'Location Source',
  longitude: 'Longitude Range',
  mkt_cookies: 'Cookies',
  msa: 'MSA',
  op: 'Operator',
  openaudience_custom: 'OpenAudience',
  os: 'OS',
  page_url: 'Page URL',
  postal_code: 'Postal Code',
  region: 'Region',
  rewarded_video: 'Rewarded Video',
  screen_location: 'In-stream Video Position',
  screen_resolution: 'Screen Resolution',
  site: 'Site ID',
  size: 'Size',
  skip: 'Video Skippability',
  state: 'State',
  traffic_user_targeting_type: 'Traffic User Targeting Type',
  user_agent: 'User Agent',
  val: 'Value',
  video_format: 'Presentation Formats',
  video_plcmt: 'Placement',
  viewability_score: 'Viewability Tier',
  vtr_score: 'View Through Rate',
};

export enum Intersect {
  INTERSECTS = 'INTERSECTS',
  NOT_INTERSECTS = 'NOT INTERSECTS',
}

export type ComparisonList = { op: ComparisonType; val: string }[];
export type ComparisonMap = {
  [K in ComparisonType]?: string[];
};

export type OptionEntriesProps = [string, string[]];
