import { isEmpty } from 'lodash';

import {
  GeographicTargetingApi,
  GeographicOption,
  RadiusCircle,
  GeoDataApi,
  GeoCirclesApi,
  GeoLocationsList,
  GeoLocation,
  GeoLocationTree,
  GeoLocationTypes,
  GeoLocationType,
  GeographicTargetingState,
  Option,
  Intersect,
  LocationSource,
  LocationSourceApi,
  BoundingBox,
  BoundingBoxOptions,
  BoundingBoxApiOptions,
  BoundingBoxOption,
} from '@openx/types';
import { capitalizeLocation } from '@openx/utils/state/geoLocationSearch';

import { locationHierarchy } from './constants';

export const getEmptyLocationState = (): GeoLocationType => ({
  [GeoLocationTypes.CONTINENT]: [],
  [GeoLocationTypes.COUNTRY]: [],
  [GeoLocationTypes.REGION]: [],
  [GeoLocationTypes.DMA]: [],
  [GeoLocationTypes.MSA]: [],
  [GeoLocationTypes.STATE]: [],
  [GeoLocationTypes.CITY]: [],
  [GeoLocationTypes.POSTAL_CODE]: [],
});

export const getEmptyLocationSourceState = () => ({ op: Intersect.INTERSECTS, val: new Set<string>() });
export const getEmptyBoundingBoxState = () => ({
  [BoundingBoxOptions.LATMAX]: '',
  [BoundingBoxOptions.LATMIN]: '',
  [BoundingBoxOptions.LONGMAX]: '',
  [BoundingBoxOptions.LONGMIN]: '',
});

const geoLocationListDataToApi = (geoList: GeoLocationsList): GeoDataApi | null => {
  const data: GeoDataApi = {};

  for (const item of geoList) {
    data[item.type] = data[item.type] ? `${data[item.type]},${item.id}` : item.id;
  }

  return Object.keys(data).length ? data : null;
};

export const transformGeoToStateObject = (geoApi: GeoLocationsList): GeoLocationType => {
  const geoObject = geoApi.reduce((acc, location) => {
    acc[location.type].push(location);

    return { ...acc };
  }, getEmptyLocationState());

  return geoObject;
};

export const transformApiCirclesToStateObject = (apiCircles: GeoCirclesApi | undefined | null): RadiusCircle[] => {
  let circles: RadiusCircle[] = [];

  if (apiCircles) {
    try {
      const { val } = apiCircles;
      circles = JSON.parse(val);
    } catch (e) {
      console.error(e);
    }
  }

  return circles;
};

export const transformApiLocationSourceToStateObject = (
  apiData: LocationSourceApi | null | undefined
): LocationSource => {
  if (apiData) {
    return {
      op: apiData.op,
      val: new Set(apiData.val.split(',')),
    };
  }

  return getEmptyLocationSourceState();
};

export const transformApiBoundingBoxToStateObject = (apiData: GeographicTargetingApi | null): BoundingBox => {
  const apiDataLatidude = apiData?.latitude;
  const apiDataLongitude = apiData?.longitude;

  let boundingBoxData = {};

  if (apiDataLatidude?.val) {
    const latidude = apiDataLatidude.val.split(' - ');

    boundingBoxData = {
      [BoundingBoxOptions.LATMIN]: latidude.at(0) || '',
      [BoundingBoxOptions.LATMAX]: latidude.at(1) || '',
    };
  }

  if (apiDataLongitude?.val) {
    const longitude = apiDataLongitude.val.split(' - ');

    boundingBoxData = {
      ...boundingBoxData,
      [BoundingBoxOptions.LONGMIN]: longitude.at(0) || '',
      [BoundingBoxOptions.LONGMAX]: longitude.at(1) || '',
    };
  }

  if (isEmpty(boundingBoxData)) return getEmptyBoundingBoxState();

  return boundingBoxData;
};

export const convertGeoObjToArray = (geoObj: GeoLocationType): GeoLocationsList => {
  const geoArray: GeoLocationsList = [];

  for (const key in geoObj) {
    geoArray.push(...geoObj[key]);
  }

  return geoArray;
};

export const mapGeographicStateToApi = (geographicState: GeographicTargetingState): GeographicTargetingApi | null => {
  const apiData: GeographicTargetingApi = {};

  const circlesState = geographicState[GeographicOption.CIRCLES];
  if (circlesState.length) {
    apiData[GeographicOption.CIRCLES] = {
      op: '<',
      val: JSON.stringify(circlesState),
    };
  }

  const includesState: GeoLocationsList = convertGeoObjToArray(geographicState[GeographicOption.INCLUDES]);
  const excludesState: GeoLocationsList =
    geographicState[GeographicOption.EXCLUDES_SUBSET].length > 0
      ? geographicState[GeographicOption.EXCLUDES_SUBSET]
      : convertGeoObjToArray(geographicState[GeographicOption.EXCLUDES]);

  if (includesState.length) {
    const apiIncludesData = geoLocationListDataToApi(includesState);
    if (apiIncludesData) {
      apiData[GeographicOption.INCLUDES] = apiIncludesData;
    }
  }

  if (excludesState.length) {
    const apiExcludesData = geoLocationListDataToApi(excludesState);
    if (apiExcludesData) {
      apiData[GeographicOption.EXCLUDES] = apiExcludesData;
    }
  }

  const locationSourceState = geographicState[GeographicOption.SOURCE];
  if (locationSourceState.val.size) {
    apiData[GeographicOption.SOURCE] = {
      op: locationSourceState.op,
      val: [...locationSourceState.val].join(','),
    };
  }

  const boundingBoxState = geographicState[GeographicOption.BOUNDING_BOX];
  if (boundingBoxState[BoundingBoxOptions.LATMIN]) {
    apiData[BoundingBoxApiOptions.LATITUDE] = {
      val: `${boundingBoxState[BoundingBoxOptions.LATMIN]} - ${boundingBoxState[BoundingBoxOptions.LATMAX]}`,
    };
  }

  if (boundingBoxState[BoundingBoxOptions.LONGMIN]) {
    apiData[BoundingBoxApiOptions.LONGITUDE] = {
      val: `${boundingBoxState[BoundingBoxOptions.LONGMIN]} - ${boundingBoxState[BoundingBoxOptions.LONGMAX]}`,
    };
  }

  return Object.keys(apiData).length ? apiData : null;
};

/** This function is responsible for removing contained localisations.
  @example
  input: africa, germany, poland, krakow, some krakow postal code, spain, barcelona
  output: africa, germany, poland, spain
   */
export const removeContainedLocalisations = (geoList: GeoLocationsList): GeoLocationsList => {
  const notContainedLocalisations: GeoLocationsList = [];

  // group localisations by types and type names
  const typesMap: Record<string, Record<string, GeoLocation>> = {};
  for (const geo of geoList) {
    if (!typesMap[geo.type]) {
      typesMap[geo.type] = {};
    }

    typesMap[geo.type][geo.name] = geo;
  }

  for (let i = 0; i < locationHierarchy.length; i++) {
    const currentType = locationHierarchy[i];
    const typesMapElements = typesMap[currentType];
    if (i === 0) {
      typesMapElements && notContainedLocalisations.push(...Object.values(typesMapElements));
      continue;
    }

    const prevTypes = locationHierarchy.slice(0, i);

    if (typesMapElements) {
      Object.values(typesMapElements).forEach(el => {
        let isContainedInAnother = false;
        for (const prevType of prevTypes) {
          const elementParentName = el[prevType];
          const elementParentMapElements = typesMap[prevType];
          if (elementParentName && elementParentMapElements && elementParentName in elementParentMapElements) {
            isContainedInAnother = true;
            break;
          }
        }
        !isContainedInAnother && notContainedLocalisations.push(el);
      });
    }
  }
  return notContainedLocalisations;
};

export const groupByUncontainedLocalisations = (
  geoToGroup: GeoLocationsList,
  uncontainedLocalisations: GeoLocationsList
): { grouped: GeoLocationTree[]; other: GeoLocationsList } => {
  /*
    grouped -> list of geo locations with additional property: childrens
    other -> not matched elements to uncontainedLocalisations
  */

  const res: {
    grouped: GeoLocationTree[];
    other: GeoLocationsList;
  } = {
    grouped: [],
    other: [],
  };

  const uncontainedLocalisationsTree: GeoLocationTree[] = uncontainedLocalisations.map(l => ({
    ...l,
    childrens: [] as GeoLocationsList,
  }));
  geoToGroup.forEach(geo => {
    let hasParent = false;

    for (const elType of locationHierarchy) {
      const parentName = `${elType}-${geo[elType]}`;
      const parent = uncontainedLocalisationsTree.find(
        geoParent => `${geoParent.type}-${geoParent.name}` === parentName
      );

      if (parent) {
        parent.childrens.push(geo);
        hasParent = true;
        break;
      }
    }
    !hasParent && res.other.push(geo);
  });

  uncontainedLocalisationsTree.forEach(geo => {
    geo.childrens.length && res.grouped.push(geo);
  });

  return res;
};

export const getChildrenDisplayName = (geoChildren: GeoLocation, parentType: GeoLocationTypes): string => {
  let name = '';
  const parentTypeIndex = locationHierarchy.indexOf(parentType);
  const childrenTypeIndex = locationHierarchy.indexOf(geoChildren.type as GeoLocationTypes);

  locationHierarchy.slice(parentTypeIndex + 1, childrenTypeIndex).forEach(locationType => {
    const locationName = geoChildren[locationType];
    if (locationName) {
      name += name ? ' > ' : '';
      name += `${capitalizeLocation(geoChildren[locationType])} (${locationType})`;
    }
  });

  name += name ? ' > ' : '';
  name += `${capitalizeLocation(geoChildren.name || 'Unknown Name')} (${geoChildren.type || 'Unknown Type'})`;

  return name || `Unknown[${geoChildren.type}]`;
};

export const filterExcludesWithoutParent = ({
  includeItems,
  excludeItems,
}: {
  includeItems: GeoLocationsList;
  excludeItems: GeoLocationsList;
}): GeoLocationsList => {
  const uncontainedLocalisations = removeContainedLocalisations(includeItems);
  const { grouped } = groupByUncontainedLocalisations(excludeItems, uncontainedLocalisations);
  const groupedLocalista = grouped.reduce((acc, current) => {
    return [...acc, ...current.childrens];
  }, [] as GeoLocationsList);

  return groupedLocalista;
};

export const isGeoEmpty = (targetingParams: GeographicTargetingState | null | undefined) => {
  if (!targetingParams) {
    return true;
  }

  const { bounding_box, circles, excludes, includes, excludes_subset, location_source } = targetingParams;

  const isIncludesEmpty = isEmpty(includes) ? true : Object.values(includes).every(value => isEmpty(value));
  const isExcludesEmpty = isEmpty(excludes) ? true : Object.values(excludes).every(value => isEmpty(value));
  const isExcludesSubsetEmpty = isEmpty(excludes_subset);
  const isCirclesEmpty = circles.length === 0;
  const isLocationSourceEmpty = !location_source.val.size;
  const isBoundingBoxEmpty = !bounding_box[BoundingBoxOptions.LATMIN] && !bounding_box[BoundingBoxOptions.LONGMIN];

  return (
    isIncludesEmpty &&
    isExcludesEmpty &&
    isExcludesSubsetEmpty &&
    isCirclesEmpty &&
    isLocationSourceEmpty &&
    isBoundingBoxEmpty
  );
};

export const validateRadius = (lat: number, lon: number, rest?: number[]): boolean =>
  !(isNaN(lat) || isNaN(lon) || lat > 90 || lat < -90 || lon > 180 || lon < -180 || !isEmpty(rest));

export const getDuplicatePostalCodes = (
  selectedPostalCodes: Set<{ country: string; postalCode: string }>,
  inputPostalCodes: string[],
  country: Option
): { duplicates: string[]; uniquePostalCodes: Set<string> } => {
  const postalCodesIds = [...selectedPostalCodes].map(({ postalCode, country }) =>
    `${country}|${postalCode}`.toLowerCase()
  );

  const duplicates: string[] = [];
  const uniquePostalCodes = new Set<string>();

  inputPostalCodes.forEach(code => {
    const postalCodeId = `${country.name}|${code}`.toLowerCase();

    if (postalCodesIds.includes(postalCodeId) || uniquePostalCodes.has(code)) {
      duplicates.push(code);
    } else {
      uniquePostalCodes.add(code);
    }
  });

  return { duplicates, uniquePostalCodes };
};

export const validatePostalCodes = (postalCodesApiResponse: GeoLocationsList, postalCodesToValidate: Set<string>) => {
  const validPostalCodes: string[] = [];
  const invalidPostalCodes: string[] = [];

  if (!postalCodesApiResponse.length) {
    return { invalidPostalCodes: [...postalCodesToValidate], validPostalCodes: [] };
  }

  const caseInsensitiveRegex = new RegExp(
    postalCodesApiResponse.map((location: GeoLocation) => location.postal_code).join('|'),
    'i'
  );

  postalCodesToValidate.forEach(code => {
    if (caseInsensitiveRegex.test(code)) {
      validPostalCodes.push(code);
    } else {
      invalidPostalCodes.push(code);
    }
  });

  return { invalidPostalCodes, validPostalCodes };
};

export const checkIfCoordinateIsValid = (fieldName: BoundingBoxOption, inputValue?: string) => {
  if (!inputValue) return false;

  const parsedValue = parseFloat(inputValue);

  if (fieldName === BoundingBoxOptions.LATMIN || fieldName === BoundingBoxOptions.LATMAX) {
    return !isNaN(parsedValue) && parsedValue <= 90 && parsedValue >= -90;
  }

  return !isNaN(parsedValue) && parsedValue <= 180 && parsedValue >= -180;
};
