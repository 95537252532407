import { Grid, FormLabel, TextField } from '@mui/material';
import { styled, css } from '@mui/material/styles';
import { useCallback, useState, useRef, KeyboardEvent, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../Button';

const StyledButton = styled(Button)`
  margin: ${({ theme }) => theme.spacing(1.125, 1, 0, 0)};
`;

const StyledFormLabel = styled(FormLabel)`
  top: ${({ theme }) => theme.spacing(-0.875)};
`;

const StyledContainerGrid = styled(Grid, { shouldForwardProp: prop => prop !== 'hasLabel' })<{ hasLabel: boolean }>`
  ${({ hasLabel, theme }) =>
    hasLabel &&
    css`
      margin-top: ${theme.spacing(-3.5)};
    `}
`;

export type CustomSizeInputProps = {
  onAdd: (item: string) => void;
  onCancel?: () => void;
  label?: string;
};

const emptyState = {
  height: {
    error: '',
    text: '',
  },
  width: {
    error: '',
    text: '',
  },
};

export const CustomSizeInput = ({ onAdd, onCancel, label }: CustomSizeInputProps) => {
  const { t } = useTranslation();

  const [state, setState] = useState(emptyState);
  const inputRef = useRef<HTMLInputElement>(null);
  const { width, height } = state;
  const addDisabled = !!(!width.text || !height.text || width.error || height.error);

  const hasLabel = !!label;

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, input: 'width' | 'height') => {
      const targetElement = e.target;
      const { value } = targetElement;

      let err;

      if (value && !value.match('^[1-9][0-9]{0,3}$')) {
        err = t('Only positive numbers lower than 10000 are allowed.');
      }

      setState({
        ...state,
        [input]: {
          error: err,
          text: value,
        },
      });
    },
    [t, state]
  );

  const onAddClick = useCallback(() => {
    if (addDisabled) {
      return;
    }
    onAdd(`${width.text}x${height.text}`);
    setState(emptyState);

    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [addDisabled, height.text, onAdd, width.text]);

  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        onAddClick();
      }
    },
    [onAddClick]
  );

  return (
    <>
      {hasLabel && <StyledFormLabel>{label}</StyledFormLabel>}
      <StyledContainerGrid container spacing={1} hasLabel={hasLabel}>
        <Grid item xs={4}>
          <TextField
            placeholder={t('[ Enter Width ]')}
            fullWidth
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, 'width')}
            margin="dense"
            data-test="adunit-input-width"
            value={state.width.text}
            helperText={state.width.error}
            error={!!state.width.error}
            onKeyDown={onKeyDown}
            inputRef={inputRef}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            placeholder={t('[ Enter Height ]')}
            fullWidth
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, 'height')}
            margin="dense"
            data-test="adunit-input-height"
            value={state.height.text}
            helperText={state.height.error}
            error={!!state.height.error}
            onKeyDown={onKeyDown}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledButton onClick={onAddClick} disabled={addDisabled} data-test="adunit-input-add">
            {t('ADD')}
          </StyledButton>
          {onCancel && (
            <StyledButton variant="text" onClick={onCancel} data-test="adunit-input-cancel">
              {t('CANCEL')}
            </StyledButton>
          )}
        </Grid>
      </StyledContainerGrid>
    </>
  );
};
