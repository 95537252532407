import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { GeographicOption, type GeographicTargetingApi, type GeographicTargetingState } from '@openx/types';

import { useTargetingContext } from '../../../utils';
import { getDefaultGeographicState } from '../constants';
import {
  transformApiBoundingBoxToStateObject,
  transformApiCirclesToStateObject,
  transformApiLocationSourceToStateObject,
  getEmptyLocationState,
  transformGeoToStateObject,
} from '../utils';

export const useGetGeoState = (targetingParams: GeographicTargetingApi | null) => {
  const [geoInitialized, setGeoInitialized] = useState<boolean>(false);
  const [geographicBaseState, setGeographicBaseState] = useState<GeographicTargetingState | null>(null);
  const [geographicState, setGeographicState] = useState<GeographicTargetingState>(getDefaultGeographicState);
  const location = useLocation();

  const isDataUpdated = useMemo(
    () => !isEqual(geographicBaseState, geographicState),
    [geographicBaseState, geographicState]
  );

  const { useFetchByGeoData } = useTargetingContext();
  const { fetchByGeoData } = useFetchByGeoData();

  useEffect(() => {
    const prepareGeographicState = async () => {
      const { includes, excludes } = targetingParams || {};

      const [fetchedIncludes, fetchedExcludes] = await Promise.all([
        includes ? fetchByGeoData(includes) : [],
        excludes ? fetchByGeoData(excludes) : [],
      ]);

      const containsSubset = fetchedIncludes.length && fetchedExcludes.length;

      const newState = {} as GeographicTargetingState;

      newState[GeographicOption.BOUNDING_BOX] = transformApiBoundingBoxToStateObject(targetingParams);
      newState[GeographicOption.CIRCLES] = transformApiCirclesToStateObject(targetingParams?.circles);
      newState[GeographicOption.INCLUDES] = transformGeoToStateObject(fetchedIncludes);
      newState[GeographicOption.SOURCE] = transformApiLocationSourceToStateObject(targetingParams?.location_source);

      if (containsSubset) {
        newState[GeographicOption.EXCLUDES] = getEmptyLocationState();
        newState[GeographicOption.EXCLUDES_SUBSET] = fetchedExcludes;
      } else {
        newState[GeographicOption.EXCLUDES] = transformGeoToStateObject(fetchedExcludes);
        newState[GeographicOption.EXCLUDES_SUBSET] = [];
      }

      setGeographicBaseState(newState);
      setGeographicState(newState);
    };

    if (!geoInitialized) {
      setGeoInitialized(true);
      prepareGeographicState();
    }
  }, [fetchByGeoData, targetingParams, geoInitialized, location]);

  return { geographicBaseState, geographicState, isDataUpdated, setGeographicBaseState, setGeographicState };
};
