import { styled, css } from '@mui/material/styles';

import { Button, ButtonProps } from '../Button';

// there is nothing to test here so we exclude this component from the coverage
// istanbul ignore file
export const StyledDrawerWrapperDiv = styled('div')<{
  gutterTop?: boolean;
  gutterBottom?: boolean;
  stickyBottom: boolean;
}>`
  margin: ${({ gutterTop, gutterBottom, theme }) => theme.spacing(gutterTop ? 2 : 0, 0, gutterBottom ? 2 : 0, 0)};

  ${({ stickyBottom, theme }) =>
    stickyBottom &&
    css`
      padding-bottom: ${theme.spacing(7)};
    `}
`;

export const StyledDrawerContainerDiv = styled('div')<{
  bgColor?: string;
  stickyBottom: boolean;
  sidePadding?: boolean;
}>`
  ${({ bgColor, theme, stickyBottom, sidePadding }) =>
    stickyBottom &&
    css`
      background-color: ${bgColor ? bgColor : theme.palette.background.paper};
      bottom: 0;
      padding-bottom: ${theme.spacing(1.5)};
      position: fixed;
      width: 100%;
      z-index: 3;

      ${sidePadding &&
      css`
        padding-left: ${theme.spacing(3)};
        padding-right: ${theme.spacing(3)};
      `}
    `}
`;

export const StyledDrawerActionButton = styled(Button)<{ variant: ButtonProps['variant'] }>`
  margin: ${({ theme, variant }) => theme.spacing(0, variant === 'text' ? 1 : 0.5)};
`;

export const StyledDrawerContentContainerDiv = styled('div')<{ variant: ButtonProps['variant'] }>`
  display: flex;
  justify-content: left;
  margin: ${({ variant, theme }) => theme.spacing(0, variant === 'text' ? -1 : -0.5)};
`;
