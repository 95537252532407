import type { SxProps, Theme } from '@mui/material';
import { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';

import {
  AllTableCriteria,
  BaseRow,
  ChangeCriteriaHandler,
  Columns,
  CriteriaDimension,
  RowAction,
  RowActionButtonBaseProps,
  RowActionProps,
} from '@openx/types';
import { prepareChangeCriteriaHandler } from '@openx/utils/lib/tableHelpers';

import { HighlightRules } from './highlightRules';
import { DataSection, DataSectionProps } from './parts/sections/DataSection';
import { ManagementSection, ManagementSectionProps } from './parts/sections/ManagementSection';
import { TableRowPropsWithData } from './parts/TableRow';

export enum TableName {
  GRANULAR_FEE_CAPS = 'granular-fee-caps',
  AD_FILTERS = 'ad_filters',
  CREATIVES_MANAGEMENT = 'creatives_management',
  ACCOUNTS = 'accounts',
  ACCOUNTS_DSP = 'accounts-dsp',
  ADUNITS = 'adunits',
  AQ_RULES = 'aq_rules',
  AQ_RULES_REPORTS = 'aq_rules_reports',
  APPS = 'apps',
  BRAND_FLOORS = 'brand_floors',
  PRICING_RULES = 'pricing_rules',
  BRANDS = 'brands',
  BUYERS = 'buyers',
  COPPA_FLAGGED = 'coppa_flagged',
  COPPA_UNFLAGGED = 'coppa_unflagged',
  DEALS = 'deals',
  DOMAINS = 'domains',
  IMPERSONATE = 'impersonate',
  INSTANCES = 'instances',
  MARKET_TOOLS = 'market_tools',
  PACKAGES = 'packages',
  TRAFFIC_SETS = 'traffic_sets',
  PARTNERS = 'partners',
  PUBLISHERS = 'publishers',
  SEATS = 'seats',
  SITES = 'sites',
  TS_DECODER = 'ts_decoder',
  USED_BY_ENTRY = 'used_by_entry',
  USERS = 'users',
  GRANULAR_DSP_FEE_CAPS = 'granular_dsp_fee_caps',
  PUBLISHER_FEE_CAPS = 'publisher_fee_caps',
  ORDERS = 'orders',
  AUCTION_PACKAGES = 'auction_packages',
}

export interface TableProps<RowT extends BaseRow, FiltersT extends Record<string, unknown> = {}> {
  name?: TableName;
  data: RowT[];
  columns: Columns<RowT>;
  onRowClick?: RowAction<RowT>;
  loading: boolean;
  noItemsLabel?: (data: RowT) => string;
  isTree?: boolean;
  fetchTreeChildren?: (data: RowT) => Promise<RowT[]>;
  isForcedExpanded?: (data: RowT) => boolean;
  treeLevelItems?: number;
  criteria?: AllTableCriteria<FiltersT>;
  onCriteriaChange?: ChangeCriteriaHandler<CriteriaDimension, FiltersT>;
  highlightRules?: HighlightRules;
  customManagementSection?: ReactNode;
  topManagementSection?: ReactNode;
  primaryRowAction?: RowActionButtonBaseProps<RowT>;
  secondaryRowAction?: RowActionButtonBaseProps<RowT>;
  dangerRowAction?: RowActionButtonBaseProps<RowT>;
  defaultRowAction?: RowActionButtonBaseProps<RowT>;
  customRowActions?: RowActionButtonBaseProps<RowT>[];
  rowActions?: RowActionProps<RowT>[];
  stickyTable?: boolean;
  enableTableHeaderCustomization?: boolean;
  renderCustomRow?: (rowProps: TableRowPropsWithData<RowT>, isForcedExpanded?: (data: RowT) => boolean) => ReactElement;
  loadingOnInit?: boolean;
  overrideTableCellStyles?: SxProps<Theme>;
  groupBy?: keyof RowT;
  forceExpandGroupedItems?: boolean;
  groupedItemsActions?: React.ReactNode;
}

export const Table = <RowT extends BaseRow, FiltersT extends {} = {}>({
  criteria,
  enableTableHeaderCustomization = true,
  highlightRules,
  onCriteriaChange,
  loadingOnInit,
  loading,
  forceExpandGroupedItems,
  groupedItemsActions,
  ...props
}: TableProps<RowT, FiltersT>): JSX.Element => {
  const [initialized, setInitialized] = useState(!loadingOnInit || props.data.length > 0);

  const { onPaginationChange, onSortChange } = useMemo(
    () => ({
      onPaginationChange: prepareChangeCriteriaHandler(CriteriaDimension.PAGINATION, onCriteriaChange),
      onSortChange: prepareChangeCriteriaHandler(CriteriaDimension.SORT, onCriteriaChange),
    }),
    [onCriteriaChange]
  );

  useEffect(() => {
    // from react 18 we need to use this in redux component to avoid displaying no results found on first render
    loadingOnInit && setInitialized(true);
  }, [loadingOnInit]);

  const managementSectionProps: ManagementSectionProps = {
    ...props,
    loading: loading || !initialized,
    onPaginationChange,
    paginationCriteria: criteria?.pagination,
  };

  const dataSectionProps: DataSectionProps<RowT> = {
    ...props,
    enableTableHeaderCustomization,
    forceExpandGroupedItems,
    groupedItemsActions,
    highlightRules,
    loading: loading || !initialized,
    onSortChange,
    sortCriteria: criteria?.sort,
  };

  return (
    <div>
      {props.topManagementSection && (
        <ManagementSection {...managementSectionProps} customManagementSection={props.topManagementSection} />
      )}
      <DataSection {...dataSectionProps} />
      <ManagementSection {...managementSectionProps} />
    </div>
  );
};
