import { ChevronRight } from '@mui/icons-material';
import { Table as MaterialTable, type SxProps, TableBody, type Theme, IconButton, TableCell } from '@mui/material';
import { Fragment, ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Placeholder } from '@openx/components/core';
import {
  BaseRow,
  ChangeSingleCriteriaAction,
  Columns,
  CriteriaDimension,
  RowAction,
  RowActionButtonBaseProps,
  RowActionProps,
  SelectOperation,
  SortCriteria,
  TableColumn,
  ToggleSelect,
} from '@openx/types';

import { HighlightRules } from '../../highlightRules';
import { allItemsSelected, anyItemSelected } from '../../SelectableTable/selectCheckers';
import { ActionsCell } from '../ActionsCell';
import ColumnFilter from '../ColumnFilter';
import { CustomTableRow } from '../CustomTableRow';
import { TableHeader } from '../TableHeader';
import { TableRow, TableRowPropsWithData } from '../TableRow';
import { TableRowTree } from '../TableRowTree';

import { ACTION_ICON_CELL, CustomColumnConfig } from './config';
import { groupData, loadCustomColumnsConfig, recalculateColumsWidth } from './utils';

function renderRowsSkeleton<RowT extends BaseRow>(columns: Columns<RowT>, rowsAmount = 3): JSX.Element[] {
  const result: JSX.Element[] = [];

  for (let i = 0; i < rowsAmount; i += 1) {
    result.push(<TableRow columns={columns} key={i} />);
  }

  return result;
}

const bookmarkRowTableCellSx = (theme: Theme) => ({
  backgroundColor: theme.palette.background.boxLight,
  borderBottom: `1px solid ${theme.palette.divider}`,
  fontWeight: 600,
  padding: `${2} 0`,
});

export interface DataSectionProps<RowT extends BaseRow> {
  name?: string;
  data: RowT[];
  columns: Columns<RowT>;
  onRowClick?: RowAction<RowT>;
  loading: boolean;
  noItemsLabel?: (data: RowT) => string;
  isTree?: boolean;
  fetchTreeChildren?: (data: RowT) => Promise<RowT[]>;
  isForcedExpanded?: (data: RowT) => boolean;
  highlightRules?: HighlightRules;
  sortCriteria?: SortCriteria;
  onSortChange: ChangeSingleCriteriaAction<CriteriaDimension.SORT>;
  primaryRowAction?: RowActionButtonBaseProps<RowT>;
  secondaryRowAction?: RowActionButtonBaseProps<RowT>;
  dangerRowAction?: RowActionButtonBaseProps<RowT>;
  defaultRowAction?: RowActionButtonBaseProps<RowT>;
  customRowActions?: RowActionButtonBaseProps<RowT>[];
  rowActions?: RowActionProps<RowT>[];
  treeLevelItems?: number;
  stickyTable?: boolean;
  enableTableHeaderCustomization: boolean;
  renderCustomRow?: (rowProps: TableRowPropsWithData<RowT>, isForcedExpanded?: (data: RowT) => boolean) => ReactElement;
  overrideTableCellStyles?: SxProps<Theme>;
  groupBy?: keyof RowT;
  toggleSelect?: ToggleSelect<RowT>;
  selectedItems?: RowT[];
  forceExpandGroupedItems?: boolean;
  groupedItemsActions?: React.ReactNode;
}

export function DataSection<RowT extends BaseRow>(props: DataSectionProps<RowT>): JSX.Element {
  const {
    name,
    noItemsLabel,
    isTree,
    fetchTreeChildren,
    isForcedExpanded,
    loading,
    data,
    onRowClick,
    primaryRowAction,
    secondaryRowAction,
    dangerRowAction,
    defaultRowAction,
    customRowActions,
    rowActions,
    treeLevelItems,
    stickyTable,
    enableTableHeaderCustomization,
    onSortChange,
    highlightRules,
    sortCriteria,
    renderCustomRow,
    overrideTableCellStyles,
    groupBy,
    toggleSelect,
    selectedItems = [],
    forceExpandGroupedItems,
    groupedItemsActions,
  } = props;

  const [columns, setColumns] = useState<Columns<RowT>>(loadCustomColumnsConfig(name, props.columns));
  const [expandedGroups, setExpandedGroups] = useState<Record<string, boolean>>({});

  const { t } = useTranslation();

  const groupedData = useMemo(() => groupData(data, groupBy), [data, groupBy]);

  const handleGroupToggle = useCallback((groupKey: string) => {
    setExpandedGroups(prevState => ({
      ...prevState,
      [groupKey]: !prevState[groupKey],
    }));
  }, []);

  useEffect(() => {
    setColumns(loadCustomColumnsConfig(name, props.columns));
  }, [props.columns, name]);

  useEffect(() => {
    if (forceExpandGroupedItems && groupedData) {
      setExpandedGroups(
        Object.keys(groupedData).reduce((acc, key) => {
          return {
            ...acc,
            [key]: true,
          };
        }, {})
      );
    }
  }, [forceExpandGroupedItems, groupedData]);

  const iconCellRenderer = useMemo(() => {
    if (
      rowActions ||
      primaryRowAction ||
      secondaryRowAction ||
      dangerRowAction ||
      defaultRowAction ||
      customRowActions
    ) {
      return (singleRowData: RowT) => (
        <ActionsCell
          rowData={singleRowData}
          actions={rowActions}
          primaryAction={primaryRowAction}
          secondaryAction={secondaryRowAction}
          dangerAction={dangerRowAction}
          defaultAction={defaultRowAction}
          customRowActions={customRowActions}
        />
      );
    }

    const placeholder = enableTableHeaderCustomization ? () => <TableCell /> : undefined;

    return placeholder;
  }, [
    dangerRowAction,
    defaultRowAction,
    customRowActions,
    enableTableHeaderCustomization,
    primaryRowAction,
    rowActions,
    secondaryRowAction,
  ]);

  const updateLocalStorage = useCallback(
    (cols: Columns<RowT>) => {
      const config: CustomColumnConfig[] = cols.map(column => {
        return { hide: !!column.hide, key: column.key };
      });

      name && localStorage.setItem(`tableHeaderConfig-${name}`, JSON.stringify(config));
    },
    [name]
  );

  const handleFilterColumn = useCallback(
    (column: TableColumn<RowT>) => {
      const updatedColumns = columns.map(singleColumn => {
        if (singleColumn.key === column.key) {
          singleColumn.hide = !singleColumn.hide;
        }
        return singleColumn;
      });

      const calcColsWidth = recalculateColumsWidth(updatedColumns);
      setColumns(calcColsWidth);
      updateLocalStorage(updatedColumns);
    },
    [columns, updateLocalStorage]
  );

  const filterColumnNode: ReactNode = useMemo(
    () => (
      <ColumnFilter
        columns={columns}
        handleFilterColumn={handleFilterColumn}
        updateLocalStorage={updateLocalStorage}
        updateColumns={setColumns}
      />
    ),
    [columns, handleFilterColumn, updateLocalStorage]
  );

  const filteredColumns = columns.filter(singleColumn => singleColumn.hide !== true);

  const headerColumns = useMemo(() => {
    const icon: ReactNode = filterColumnNode;
    const actionCell = enableTableHeaderCustomization ? [{ key: ACTION_ICON_CELL, title: icon }] : [];

    return [...filteredColumns, ...actionCell];
  }, [filteredColumns, filterColumnNode, enableTableHeaderCustomization]);

  const getRowComponent = useCallback(
    (row: RowT, index: number, _: RowT[], inBookmark?: boolean): JSX.Element => {
      const rowProps = {
        columns: filteredColumns,
        dangerRowAction,
        defaultRowAction,
        highlightRules,
        iconCellRenderer,
        key: row.id || index,
        noItemsLabel,
        onRowClick,
        overrideTableCellStyles,
        primaryRowAction,
        rowActions,
        rowData: row,
        secondaryRowAction,
        treeLevelItems,
      };

      if (isTree) {
        return (
          <TableRowTree
            {...rowProps}
            key={`${rowProps.key}|${rowProps.rowData?.children?.length || 0}`}
            isForcedExpanded={isForcedExpanded}
            fetchTreeChildren={fetchTreeChildren}
          />
        );
      }

      if (renderCustomRow) {
        return (
          <CustomTableRow
            key={rowProps.key}
            isForcedExpanded={isForcedExpanded}
            renderCustomRow={renderCustomRow}
            rowProps={rowProps}
          />
        );
      }

      const { key, ...tableProps } = rowProps;

      return (
        <TableRow
          {...tableProps}
          sx={
            inBookmark
              ? {
                  [`& > ${columns[0]?.key === 'select' ? ':nth-of-type(2)' : ':first-child'}`]: {
                    paddingLeft: '44px',
                  },
                  backgroundColor: '#FAFAFA',
                }
              : undefined
          }
          key={key}
        />
      );
    },
    [
      filteredColumns,
      dangerRowAction,
      defaultRowAction,
      noItemsLabel,
      highlightRules,
      iconCellRenderer,
      onRowClick,
      primaryRowAction,
      rowActions,
      secondaryRowAction,
      treeLevelItems,
      isTree,
      isForcedExpanded,
      fetchTreeChildren,
      renderCustomRow,
      overrideTableCellStyles,
      columns,
    ]
  );

  const renderGroup = useCallback(
    (groupKey: string, rows: RowT[]) => {
      const isExpanded = expandedGroups[groupKey];

      return (
        <Fragment key={`group-${groupKey}`}>
          <tr>
            {columns[0]?.key === 'select' && (
              <TableCell sx={theme => ({ ...bookmarkRowTableCellSx(theme), paddingLeft: 4 })}>
                <Checkbox
                  checked={anyItemSelected(rows, selectedItems)}
                  data-test={'group-checkbox'}
                  indeterminate={anyItemSelected(rows, selectedItems) && !allItemsSelected(rows, selectedItems)}
                  onClick={e => e.stopPropagation()}
                  onChange={({ target: { checked } }) => {
                    toggleSelect?.(rows, checked ? SelectOperation.SELECT : SelectOperation.UNSELECT);
                  }}
                  color="primary"
                />
              </TableCell>
            )}

            <TableCell
              colSpan={groupedItemsActions ? filteredColumns.length - 4 : filteredColumns.length}
              sx={bookmarkRowTableCellSx}
              data-test={'group-bookmark'}
            >
              <div style={{ cursor: 'pointer' }} onClick={() => handleGroupToggle(groupKey)}>
                <span>
                  <IconButton
                    sx={{
                      marginRight: 2,
                    }}
                    key="arrowExpand"
                    aria-label="arrowExpand"
                    data-test="expand-icon"
                    data-expandicon="expandRow"
                    size="small"
                  >
                    <ChevronRight
                      sx={{
                        transform: isExpanded ? 'rotate(90deg)' : '',
                      }}
                      data-expandicon="expandRow"
                      color="primary"
                    />
                  </IconButton>{' '}
                  {groupKey}
                </span>
              </div>
            </TableCell>

            {!!groupedItemsActions && (
              <TableCell colSpan={4} sx={theme => ({ ...bookmarkRowTableCellSx(theme), textAlign: 'right' })}>
                {groupedItemsActions}
              </TableCell>
            )}
          </tr>

          {isExpanded && rows.map((row, index) => getRowComponent(row, index, rows, true))}
        </Fragment>
      );
    },
    [
      expandedGroups,
      toggleSelect,
      columns,
      selectedItems,
      filteredColumns.length,
      handleGroupToggle,
      getRowComponent,
      groupedItemsActions,
    ]
  );

  const rows = useMemo(() => {
    if (groupBy) {
      return Object.entries(groupedData).map(([groupKey, rows]) => renderGroup(groupKey, rows));
    }

    return data.map(getRowComponent);
  }, [data, getRowComponent, groupBy, groupedData, renderGroup]);

  const resultNotFound = !loading && data.length === 0;

  if (resultNotFound) {
    return (
      <Placeholder
        title={t('No results found')}
        subtitle={t('Please try different criteria in order to find matching results')}
      />
    );
  }

  const tableBody = loading ? renderRowsSkeleton(headerColumns) : rows;

  return (
    <MaterialTable>
      <TableHeader
        onChange={onSortChange}
        columns={headerColumns}
        criteria={sortCriteria}
        stickyTable={stickyTable}
        overrideTableCellStyles={overrideTableCellStyles}
      />
      <TableBody data-test={loading ? 'table-body-loading' : 'table-body'}>{tableBody}</TableBody>
    </MaterialTable>
  );
}
